exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-e-book-js": () => import("./../../../src/pages/e-book.js" /* webpackChunkName: "component---src-pages-e-book-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-umow-sie-js": () => import("./../../../src/pages/umow-sie.js" /* webpackChunkName: "component---src-pages-umow-sie-js" */),
  "component---src-pages-wyslano-js": () => import("./../../../src/pages/wyslano.js" /* webpackChunkName: "component---src-pages-wyslano-js" */)
}

